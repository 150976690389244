import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Image from "gatsby-image"
import WriteBlog from  "../../components/cards/writeblog"
import Conetist from  "../../components/cards/conetist"

const BlogPostTemplate = ({ data, pageContext, location }) => {
  const post = data.markdownRemark
  const siteTitle = data.site.siteMetadata.title
  const { previous, next } = pageContext

  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
        image={post.frontmatter.image.publicURL}
      />
      <article className="blogPost">
        <header>
          <h1>
            {post.frontmatter.title}
          </h1>
        </header>
        <p>
        </p>
        <div className="columnLeft">
        <section dangerouslySetInnerHTML={{ __html: post.html }} />
        <footer>
            <Image
              fixed={post.frontmatter.author.profilepicture.childImageSharp.fixed}
              alt={post.frontmatter.author.name}
              style={{
                marginBottom: 0,
                minWidth: 50,
                borderRadius: `100%`,
              }}
              imgStyle={{
                borderRadius: `50%`,
              }}
            />
            <p>
              Ecrit par <strong>{post.frontmatter.author.name}</strong> {post.frontmatter.author.bio}
              {` `}
              <br />
              <a href={`https://twitter.com/${post.frontmatter.author.twitter}`}>
                Twitter @{post.frontmatter.author.twitter}
              </a><br/>
              Le {post.frontmatter.date} <br />
            </p>
        </footer>
        </div>
        <div className="columnRight">
          <img src={post.frontmatter.image.publicURL} />
        <WriteBlog />
        <Conetist type="captures" />
        <Conetist type="map" />
        <Conetist type="video" />
        </div>
      </article>
 
      <nav>
        <ul
          style={{
            display: `flex`,
            flexWrap: `wrap`,
            justifyContent: `space-between`,
            listStyle: `none`,
            padding: 0,
          }}
        >
          <li>
            {previous && (
              <Link to={previous.fields.slug} rel="prev">
                ← {previous.frontmatter.title}
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link to={next.fields.slug} rel="next">
                {next.frontmatter.title} →
              </Link>
            )}
          </li>
        </ul>
      </nav>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "DD MMMM, YYYY", locale: "fr")
        description
        image{
          publicURL
        }
        author {
          id
          bio 
          name
          twitter
          profilepicture {
            childImageSharp {
              fixed(width: 50, height: 50) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }      
    }
  }
`
