import React, {Component} from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import Image from "../../components/image"
import SEO from "../../components/seo"
import Img from "gatsby-image"
import { render } from "react-dom"
import ButtonDownload from  "../../components/buttonDownload"


const ApplicationPage = () => (
  <Layout>
    <SEO title="L'application gratuite pour les infirmiers libéraux" />
    <div className='line blue-start prez'>
      <img src="/images/background.svg" className="background" />
      <Image src="captures.png" className='image' width="400" />    
      <p className='text'>
          Disponible pour iOS (Apple) et Android (Goolgle). Installez l'application sur votre mobile.
      </p>
      <ButtonDownload mobile="iOS" text="iOS Apple" />    
      <ButtonDownload mobile="Android" text="Android Google"  />    
    </div>
  </Layout>
)

export default ApplicationPage;
