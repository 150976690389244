import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import ButtonDownload from  "../buttonDownload"
import Image from  "../image"

const ConetistContent = (props) =>{ 

  switch(props.type){
    case "captures":
      return(
        <>
        <div className="section">
            <p>L'application mobile qui donne du temps aux infirmiers libéraux !</p>
        </div>
        <Image src="captures.png" className='image' width="400" />   
        </>
      );
    case "map":
      return(
        <>
        <div className="section">
            <p>L'application mobile qui vous fait gagner du temps dans votre quotidien!</p>
        </div>
        <Image src="map-min.png" className='image' width="400" />   
        </>
      );
      case "video":
        return(
          <>
            <div className="section">
                <p>L'application mobile qui vous fait gagner du temps dans votre quotidien!</p>
            </div>
            <iframe width="220"  src="https://www.youtube.com/embed/fv5Xg42c43Y" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </>
        );
      default:
      return(
        <></>   
      );
  }
}

  const Conetist = (props) =>{ 
    const data = useStaticQuery(graphql`
    query {
      file(relativePath: {eq: "blog-post.svg"}){
        publicURL
      }
    }
    `);
    return(
        <div className="card">
        <div className="back"></div>
        <div className="header">
            <h2>Conetist</h2>
        </div>
          <ConetistContent type={props.type} />
          <ButtonDownload /> 
        </div>
        )      
  }

  export default Conetist;
  